/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        
        //     $('#jqWidth').html(width);      // Display the width
        //     $('#jqHeight').html(height);    // Display the height
        // $(document).ready(jqUpdateSize);    // When the page first loads
        // $(window).resize(jqUpdateSize);    //When window resizes
        var width = window.innerWidth;
        var interiorLookup = $('.main div').hasClass('interior_content');
        if (width >= 1024 && interiorLookup === true) {
          var scrollStart = 0;
          var interiorStart = $('.page_content');
          var interiorOffset = interiorStart.offset();
          $(document).scroll(function() { 
              scrollStart = $(this).scrollTop();
              if (scrollStart > interiorOffset.top) {
                  $('.interior_header__navbar').css('background-color', 'rgba(1,66,116,1.0)');
                  $('.interior_header__navbar').css('border', 'none');
                  $('.header__logo').css('border', 'none');
                  $('.header__info').css('border', 'none');
                  $('.nav-primary ul li').css('border', 'none');
              } else {
                  $('.interior_header__navbar').css('background-color', 'rgba(1,66,116,0.5)');
                  $('.interior_header__navbar').css('border-bottom', '1px solid rgba(300,300,300,0.5)');
                  $('.header__logo').css('border-right', '1px solid rgba(300,300,300,0.5)');
                  $('.header__info').css('border-bottom', '1px solid rgba(300,300,300,0.5)');
                  $('.nav-primary ul li').css('border-right', '1px solid rgba(300,300,300,0.5)');
                  $('.nav-primary ul li:last-of-type').css('border-right', 'none');
              }
          });
        } else if (width >= 1024) {
          var scrollStart = 0;
          var startChange = $('.welcome');
          var offset = startChange.offset();
          var offsetHalf = offset.top / 2;
          $(document).scroll(function() { 
              scrollStart = $(this).scrollTop();
              if(scrollStart > offsetHalf) {
                  $('.header__navbar').css('background-color', '#014274');
                  $('.header__navbar').css('border', 'none');
                  $('.header__logo').css('border', 'none');
                  $('.header__info').css('border', 'none');
                  $('.nav-primary ul li').css('border', 'none');
              } else {
                  $('.header__navbar').css('background-color', 'transparent');
                  $('.header__navbar').css('border-bottom', '1px solid rgba(300,300,300,0.5)');
                  $('.header__logo').css('border-right', '1px solid rgba(300,300,300,0.5)');
                  $('.header__info').css('border-bottom', '1px solid rgba(300,300,300,0.5)');
                  $('.nav-primary ul li').css('border-right', '1px solid rgba(300,300,300,0.5)');
                  $('.nav-primary ul li:last-of-type').css('border-right', 'none');
              }
          });
        }
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
